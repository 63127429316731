// borrowed from:
// https://github.com/supabase/ui/blob/master/src/components/Auth/UserContext.tsx
import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'

import { Feature } from '~/types'
import { fetchFeatures } from '~/utils/queries/fetchFeature'

const FeaturesContext = createContext<{
  features?: Feature[]
}>(null)

export interface Props {
  [propName: string]: any
}

export const FeaturesContextProvider = (props: Props) => {
  // supabaseのpublic.users上のuser (auth.usersではない)
  const { data: features } = useQuery('features', () => fetchFeatures(), {
    staleTime: 1000 * 60 * 3, // 3min
  })

  const value = {
    features,
  }
  return <FeaturesContext.Provider value={value} {...props} />
}

export const useFeature = (name: string) => {
  const context = useContext(FeaturesContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`)
  }

  const features = context.features
  const feature = features.find((feature) => feature.name === name)
  return feature?.is_enabled ? true : false
}
