import { Feature } from '~/types'
import { supabase } from '~/utils/supabase'

// TODO: adminページでしか使わない。 admin onlyにする
export const fetchFeature = async (params: { name: string }) => {
  return await supabase
    .from<Feature>('features')
    .select('id, name, is_enabled')
    .eq('name', params.name)
    .limit(1)
    .maybeSingle()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchFeatures = async () => {
  return await supabase
    .from<Feature>('features')
    .select('id, name, is_enabled')
    .eq('is_enabled', true)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}
