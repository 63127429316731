import '~/styles/globals.css'

import { ShortcutProvider } from '@shopify/react-shortcuts'
import type { AppProps } from 'next/app'
import { DefaultSeo } from 'next-seo'
import { GoogleAnalytics, usePagesViews } from 'nextjs-google-analytics'
import { Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { SpatialNavProvider } from '~/components/Layout/SpatialNav'
import { seoSettings } from '~/site.config'
import { FeaturesContextProvider } from '~/utils/FeaturesContext'
import { supabase } from '~/utils/supabase'
import { UserContextProvider } from '~/utils/UserContext'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      // react-queryは自動ではなく、自分でinvalidateする前提になっていた
      // あまり長い時間にすると古いデータ見えてしまう
      staleTime: 3000, // 3秒
    },
  },
})

function MyApp({ Component, pageProps, router: { route } }: AppProps) {
  usePagesViews()

  return (
    <>
      <GoogleAnalytics />
      <DefaultSeo {...seoSettings} />
      <Suspense fallback="">
        <QueryClientProvider client={queryClient}>
          <UserContextProvider supabase={supabase}>
            <ShortcutProvider>
              <SpatialNavProvider>
                <FeaturesContextProvider>
                  <Component {...pageProps} />
                </FeaturesContextProvider>
              </SpatialNavProvider>
            </ShortcutProvider>
          </UserContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Suspense>
      <Toaster
        toastOptions={{
          position: 'top-center',
          duration: 5000,
        }}
      />
    </>
  )
}

export default MyApp
