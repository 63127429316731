import { NextSeoProps } from 'next-seo'

export const siteSettings = {
  name: '100 Program',
  officialName: '100 Program',
  description: '100 Programのアプリサイト',
  url: 'https://example.com/',
  since: 2021,
}

export const seoSettings: NextSeoProps = {
  // titleがundefinedの時は、defaultTitleが使われる
  title: undefined,
  defaultTitle: siteSettings.name,
  titleTemplate: `%s | ${siteSettings.officialName}`,
  openGraph: {
    type: 'website',
    locale: 'ja_JP',
    site_name: siteSettings.officialName,
  },
  twitter: {
    handle: '@todaitotexas',
    site: '@todaitotexas',
    cardType: 'summary_large_image',
  },
}

export const indexSettings: NextSeoProps = {
  // titleがundefinedの時は、defaultTitleが使われる
  description: siteSettings.description,
  canonical: siteSettings.url,
  openGraph: {
    url: siteSettings.url,
    images: [{ url: `${siteSettings.url.replace(/\/$/, '')}/og/default.png` }],
  },
}
