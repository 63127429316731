/*
see:
https://react-query.tanstack.com/guides/suspense

https://codesandbox.io/embed/github/tannerlinsley/react-query/tree/master/examples/suspense?codemirror=1
 */

import { ApiResponse } from '@elastic/elasticsearch'
import { SearchResponse } from '@elastic/elasticsearch/api/types'

import {
  Fire,
  History,
  Idea,
  Interest,
  Signal,
  SignalStatus,
  Status,
  Team,
  TeamConnection,
  User,
  Vote,
} from '~/types'
import { supabase } from '~/utils/supabase'

export const fetchCurrentUser = async () => {
  // console.log('fetching current user')
  const user = supabase.auth.user()

  return await supabase
    .from<User>('users')
    .select(
      'id, picture, full_name, full_name_en, slack_id, profile, org, slack_channel, keywords, is_celebrated'
    )
    .eq('id', user.id)
    .limit(1)
    .single()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchUser = async ({ id }: { id: string }) => {
  return await supabase
    .from<User>('users')
    .select(
      // !user_idがないと壊れる
      'id, picture, full_name, full_name_en, slack_id, profile, org, slack_channel, keywords, ideas!user_id(id, created_at, updated_at, title, excerpt, status, total_votes(points), my_votes:votes( point )), is_celebrated, team_connections(is_leader, team:teams(id, slack_channel_name))'
    )
    .eq('id', id)
    // ideas.created_atでソート
    .order('created_at', {
      foreignTable: 'ideas',
      ascending: false,
    })
    .limit(1)
    .single()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchAllUsers = async () => {
  return await supabase
    .from<User>('users_ordered_by_this_week')
    .select('*, team_connections(team:teams(slack_channel_name))')
    .neq('is_hidden', true)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchAllIdeas = async () => {
  return await supabase
    .from<Idea>('ideas')
    .select(
      // user:usersとすると動かない。（ここではusersはテーブル名)
      // hintを設定する必要がある
      // see: https://postgrest.org/en/v8.0/api.html#hint-disambiguation
      // 「hint can be a table name, foreign key constraint name or column name.」とある
      'id, created_at, updated_at, title, excerpt, html, user_id, status, user:users!user_id(id, picture, full_name, slack_id), total_votes(points), my_votes:votes( point ), fires(count)'
    )
    .eq('status', Status.PUBLISHED)
    .order('created_at', {
      ascending: false,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const countAllIdeas = async () => {
  return await supabase
    .from<Idea>('ideas')
    .select('id', { count: 'exact' })
    // .eq('status', Status.PUBLISHED)
    .then(({ error, data, count }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      // countをreturn
      return count
    })
}

export const fetchAllIdeasPaginated = async ({
  // 1ページ目から始める
  pageParam = 1,
}: {
  pageParam: number
}) => {
  const size = 20
  // 1ページ目:0, 2ページ目: 20, 3ページ目: 40....
  const from = pageParam * size - size
  // 1ページ目:19, 2ページ目: 39, 3ページ目: 59....
  const to = from + size - 1

  const user = supabase.auth.user()
  return await supabase
    .from<Idea>('ideas')
    .select(
      // user:usersとすると動かない。（ここではusersはテーブル名)
      // hintを設定する必要がある
      // see: https://postgrest.org/en/v8.0/api.html#hint-disambiguation
      // 「hint can be a table name, foreign key constraint name or column name.」とある
      'id, created_at, updated_at, title, excerpt, html, user_id, status, user:users!user_id(id, picture, full_name, slack_id), total_votes(points), my_votes:votes( point ), total_interests(total), fires(count)'
    )
    .eq('status', Status.PUBLISHED)
    // @ts-expect-error
    .eq('votes.user_id', user.id)
    .order('created_at', {
      ascending: false,
    })
    .range(from, to)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const searchIdeas = async (params: { query: string }) => {
  const res = await fetch(`/api/search/idea?query=${params.query}`)
  const json = await res.json()
  const result: ApiResponse<SearchResponse<Idea>> = json.result

  // TODO: 検索結果のハイライトする
  const hits = result.body.hits.hits
  let ids: number[]
  if (hits) {
    ids = hits.map((hit) => {
      return Number(hit._id)
    })
  }

  const user = supabase.auth.user()
  const ideas = await supabase
    .from<Idea>('ideas')
    .select(
      'id, created_at, updated_at, title, excerpt, html, user_id, status, user:users!user_id(id, picture, full_name, slack_id), total_votes(points), my_votes:votes( point ), total_interests(total), fires(count)'
    )
    .eq('status', Status.PUBLISHED)
    // @ts-expect-error
    .eq('votes.user_id', user.id)
    // @ts-expect-error
    .in('id', ids)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })

  // とってきた後に順序が壊れてしまうので再度ソートする
  // https://furukawahiroaki.com/javascript-sort.html
  ideas.sort((x, y) => {
    // bigintとnumberの問題でts errorが出る
    // @ts-expect-error
    return ids.indexOf(x.id) - ids.indexOf(y.id)
  })

  return ideas
}

export const fetchPopularIdeasPaginated = async ({
  // 1ページ目から始める
  pageParam = 1,
}: {
  pageParam: number
}) => {
  const size = 20
  // 1ページ目:0, 2ページ目: 20, 3ページ目: 40....
  const from = pageParam * size - size
  // 1ページ目:19, 2ページ目: 39, 3ページ目: 59....
  const to = from + size - 1

  const user = supabase.auth.user()
  return await supabase
    .from<Idea>('ideas_order_by_votes')
    .select(
      // user:usersとすると動かない。（ここではusersはテーブル名)
      // hintを設定する必要がある
      // see: https://postgrest.org/en/v8.0/api.html#hint-disambiguation
      // 「hint can be a table name, foreign key constraint name or column name.」とある
      'id, created_at, updated_at, title, excerpt, html, user_id, status, user:users!user_id(id, picture, full_name, slack_id), total_votes(points), my_votes:votes( point ), total_interests(total), fires(count)'
    )
    .eq('status', Status.PUBLISHED)
    // @ts-expect-error
    .eq('votes.user_id', user.id)
    .range(from, to)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchLessVisitedIdeasPaginated = async ({
  // 1ページ目から始める
  pageParam = 1,
}: {
  pageParam: number
}) => {
  const size = 20
  // 1ページ目:0, 2ページ目: 20, 3ページ目: 40....
  const from = pageParam * size - size
  // 1ページ目:19, 2ページ目: 39, 3ページ目: 59....
  const to = from + size - 1

  const user = supabase.auth.user()
  return await supabase
    .from<Idea>('ideas_less_visited')
    .select(
      // user:usersとすると動かない。（ここではusersはテーブル名)
      // hintを設定する必要がある
      // see: https://postgrest.org/en/v8.0/api.html#hint-disambiguation
      // 「hint can be a table name, foreign key constraint name or column name.」とある
      'id, created_at, updated_at, title, excerpt, html, user_id, status, user:users!user_id(id, picture, full_name, slack_id), total_votes(points), my_votes:votes( point ), total_interests(total), fires(count)'
    )
    .eq('status', Status.PUBLISHED)
    // @ts-expect-error
    .eq('votes.user_id', user.id)
    .range(from, to)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchDottedIdeasPaginated = async ({
  // 1ページ目から始める
  pageParam = 1,
}: {
  pageParam: number
}) => {
  const size = 20
  // 1ページ目:0, 2ページ目: 20, 3ページ目: 40....
  const from = pageParam * size - size
  // 1ページ目:19, 2ページ目: 39, 3ページ目: 59....
  const to = from + size - 1

  const user = supabase.auth.user()
  return await supabase
    .from<Idea>('ideas_with_vote_count')
    .select(
      // user:usersとすると動かない。（ここではusersはテーブル名)
      // hintを設定する必要がある
      // see: https://postgrest.org/en/v8.0/api.html#hint-disambiguation
      // 「hint can be a table name, foreign key constraint name or column name.」とある
      'id, created_at, updated_at, title, excerpt, html, user_id, status, user:users!user_id(id, picture, full_name, slack_id), total_votes(points), my_votes:votes( point ), total_interests(total), fires(count)'
    )
    // @ts-expect-error
    .eq('voter_id', user.id)
    // @ts-expect-error
    .eq('votes.user_id', user.id)
    .range(from, to)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchFiredIdeasPaginated = async ({
  // 1ページ目から始める
  pageParam = 1,
}: {
  pageParam: number
}) => {
  const size = 20
  // 1ページ目:0, 2ページ目: 20, 3ページ目: 40....
  const from = pageParam * size - size
  // 1ページ目:19, 2ページ目: 39, 3ページ目: 59....
  const to = from + size - 1

  const user = supabase.auth.user()
  return await supabase
    .from<Idea>('ideas_with_fire')
    .select(
      // user:usersとすると動かない。（ここではusersはテーブル名)
      // hintを設定する必要がある
      // see: https://postgrest.org/en/v8.0/api.html#hint-disambiguation
      // 「hint can be a table name, foreign key constraint name or column name.」とある
      'id, created_at, updated_at, title, excerpt, html, user_id, status, user:users!user_id(id, picture, full_name, slack_id), total_votes(points), my_votes:votes( point ), total_interests(total), fires(count)'
    )
    // @ts-expect-error
    .eq('votes.user_id', user.id)
    .range(from, to)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchMyIdeas = async () => {
  const user = supabase.auth.user()

  return await supabase
    .from<Idea>('ideas')
    .select(
      'id, created_at, updated_at, title, excerpt, user_id, status, total_votes(points), my_votes:votes( point ), total_interests(total), interests(id, comment, user_id, created_at, user:users(id, picture, full_name, slack_id) ), fires(count), is_staff_picked'
    )
    .eq('user_id', user.id)
    // @ts-expect-error
    .eq('votes.user_id', user.id)
    .order('updated_at', {
      ascending: false,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchIdea = async ({ id }: { id: string }) => {
  const user = supabase.auth.user()

  return await supabase
    .from<Idea>('ideas')
    .select(
      'id, created_at, updated_at, title, excerpt, html, user_id, status, user:users!user_id(id, picture, full_name, slack_id), total_votes(points), my_votes:votes( point ), my_interests:interests(count), total_interests(total), fires(count)'
    )
    // @ts-expect-error
    .eq('votes.user_id', user.id)
    // @ts-expect-error
    .eq('interests.user_id', user.id)
    .eq('id', id)
    .limit(1)
    .single()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const upsertIdea = async (params: Partial<Idea>) => {
  return await supabase
    .from<Idea>('ideas')
    .upsert([
      {
        id: params.id,
        title: params.title,
        html: params.html,
        excerpt: params.excerpt,
        user_id: params.user_id,
        status: params.status,
        updated_at: new Date(),
      },
    ])
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const updateUserKeywords = async (
  params: Partial<Pick<User, 'id' | 'keywords'>>
) => {
  return await supabase
    .from<User>('users')
    .update({
      keywords: params.keywords,
    })
    .match({
      id: params.id,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const updateUserIntro = async (
  params: Partial<Pick<User, 'id' | 'profile'>>
) => {
  return await supabase
    .from<User>('users')
    .update({
      profile: params.profile,
    })
    .match({
      id: params.id,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const updateUserProfile = async (
  params: Partial<
    Pick<
      User,
      'id' | 'full_name' | 'full_name_en' | 'profile' | 'org' | 'keywords'
    >
  >
) => {
  return await supabase
    .from<User>('users')
    .update({
      full_name: params.full_name,
      full_name_en: params.full_name_en,
      profile: params.profile,
      org: params.org,
      keywords: params.keywords,
    })
    .match({
      id: params.id,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const updateUserSlackChannelId = async (
  params: Pick<User, 'id' | 'slack_channel'>
) => {
  return await supabase
    .from<User>('users')
    .update({
      slack_channel: params.slack_channel,
    })
    .match({
      id: params.id,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const addVote = async (params: { idea_id: bigint; point?: number }) => {
  // console.log('fetching current user')
  const user = supabase.auth.user()

  return await supabase
    .from<Vote>('votes')
    .upsert([
      {
        user_id: user.id,
        idea_id: params.idea_id,
        point: params.point,
      },
    ])
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const addInterest = async (params: { idea_id: bigint }) => {
  const user = supabase.auth.user()

  return await supabase
    .from<Interest>('interests')
    .insert([
      {
        user_id: user.id,
        idea_id: params.idea_id,
      },
    ])
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const deleteInterest = async (params: { idea_id: bigint }) => {
  const user = supabase.auth.user()

  return await supabase
    .from<Interest>('interests')
    .delete()
    .match({
      user_id: user.id,
      idea_id: params.idea_id,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const getUserBySlackId = async (params: { slackId: string }) => {
  return await supabase
    .from<User>('users')
    .select('id, slack_channel')
    .eq('slack_id', params.slackId)
    .limit(1)
    .single()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const getUsersBySlackIds = async (params: { slackIds: string[] }) => {
  return await supabase
    .from<User>('users')
    .select('id, picture, full_name, slack_id, keywords')
    .in('slack_id', params.slackIds)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const addHistoryViaApi = async (params: {
  minutes: number
  userId: string
  text: string
  client_msg_id: string
}) => {
  return await supabase
    .from<History>('histories')
    .insert([
      {
        user_id: params.userId,
        minutes: params.minutes,
        text: params.text,
        client_msg_id: params.client_msg_id,
      },
    ])
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchTotalMinutes = async (params: { userId: string }) => {
  return await supabase
    .from<History>('total_minutes')
    .select('minutes')
    .eq('user_id', params.userId)
    .limit(1)
    .maybeSingle()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchWeeklyMinutes = async (params: { userId: string }) => {
  return await supabase
    .from<History>('total_minutes_this_week')
    .select('minutes')
    .eq('user_id', params.userId)
    .limit(1)
    .maybeSingle()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchHistoryTexts = async () => {
  const user = supabase.auth.user()
  return await supabase
    .from<History>('histories')
    .select('id, text, created_at')
    .eq('user_id', user.id)
    .order('created_at', {
      ascending: false,
    })
    .limit(20)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const deleteHistoryText = async (params: { id: bigint }) => {
  return await supabase
    .from<History>('histories')
    .delete()
    .match({
      id: params.id,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchIdeaViaAPI = async (params: { idea_id: bigint }) => {
  return await supabase
    .from<Idea>('ideas')
    .select(
      'id, created_at, title, excerpt, user:users!user_id(id, slack_id, slack_channel)'
    )
    .eq('id', params.idea_id)
    .limit(1)
    .single()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchMyInterests = async () => {
  const user = supabase.auth.user()

  return await supabase
    .from<Interest>('interests')
    .select(
      'id, comment, user_id, idea:ideas!idea_id(id, created_at, updated_at, title, excerpt, status, total_votes(points), my_votes:votes( point ), total_interests(total), user:users!user_id(id, picture, full_name, slack_id) )'
    )
    .eq('user_id', user.id)
    // @ts-expect-error
    .eq('idea.votes.user_id', user.id)
    .order('created_at')
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

// https://www.to-r.net/media/supabase-next/
// storage の key から bucket 名を取り除く
const removeBucketPath = (key: string, bucketName: string) => {
  return key.slice(bucketName.length + 1) // "/"の分だけ加算している
}

export const uploadImage = async (file: File) => {
  const user = supabase.auth.user()

  const data = await supabase.storage
    .from('public')
    .upload(`${user.id}/${file.name}`, file, {
      upsert: true,
      cacheControl: '3600',
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })

  // https://github.com/supabase/supabase/discussions/4771
  return removeBucketPath(data.Key, 'public')
}

export const getImageSrc = (key: string) => {
  return supabase.storage.from('public').getPublicUrl(key).publicURL
}

export const fetchLessVisitedIdeas = async () => {
  return await supabase
    .from<Idea>('less_visited_ideas')
    .select('id, title')
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const addFire = async (params: { idea_id: bigint }) => {
  const user = supabase.auth.user()

  return await supabase
    .from<Fire>('fires')
    .insert([
      {
        user_id: user.id,
        idea_id: params.idea_id,
      },
    ])
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const deleteFire = async (params: { idea_id: bigint }) => {
  const user = supabase.auth.user()

  return await supabase
    .from<Fire>('fires')
    .delete()
    .match({
      user_id: user.id,
      idea_id: params.idea_id,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchFiredIdea = async () => {
  const user = supabase.auth.user()

  return await supabase
    .from<Fire>('fires')
    .select('idea_id')
    .eq('user_id', user.id)
    .limit(1)
    .maybeSingle()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchAllFiredIdeas = async () => {
  return await supabase
    .from<Idea>('ideas_with_fire')
    .select(
      'id, created_at, updated_at, title, excerpt, html, user_id, status, user:users!user_id(id, picture, full_name, slack_id), total_votes(points), my_votes:votes( point ), total_interests(total), fires(count)'
    )
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const insertMatchingNode = async () => {
  const user = supabase.auth.user()

  return await supabase
    .from('matching_nodes')
    .insert({ user_id: user.id })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const upsertMatchingInput = async (params: { to_id: string }) => {
  const user = supabase.auth.user()

  return await supabase
    .from('matching_edges')
    .upsert(
      { from_id: user.id, to_id: params.to_id },
      {
        onConflict: 'from_id',
      }
    )
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchMatchingNodes = async () => {
  return await supabase
    .from('matching_nodes')
    .select('user_id')
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchMatchingNodesUsers = async () => {
  return await supabase
    .from('matching_nodes_users')
    .select('*')
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchMatchingEdgesDisplay = async () => {
  return await supabase
    .from('matching_edges_display')
    .select('*')
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchMatchingResult = async () => {
  return await supabase
    .from<User>('matching_rooms')
    .select('*')
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchSignal = async (params: { name: string }) => {
  return await supabase
    .from<Signal>('signals')
    .select('*')
    .eq('name', params.name)
    .single()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const updateSignal = async (params: {
  name: string
  status: SignalStatus
}) => {
  return await supabase
    .from<Signal>('signals')
    .update({
      status: params.status,
    })
    .match({
      name: params.name,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchMyMatchingResult = async () => {
  const user = supabase.auth.user()

  return await supabase
    .from<
      User & {
        room: number
      }
    >('matching_rooms')
    .select('*')
    .eq('id', user.id)
    .maybeSingle()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchAllMatchingResult = async () => {
  return await supabase
    .from<User[]>('matching_rooms')
    .select('room, full_name, full_name_en, picture')
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchMatchingResultByRoom = async (params: {
  roomNumber: number
}) => {
  return await supabase
    .from<
      User & {
        room: number
      }
    >('matching_rooms')
    .select('*')
    .eq('room', params.roomNumber)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const resetMatchingTables = async () => {
  await supabase.rpc('reset_matching_tables').then(({ error, data }) => {
    if (error) {
      console.log('supabase error', error)
      throw error
    }
    return data
  })
}

export const updateInterestComment = async (
  params: Pick<Interest, 'comment' | 'idea_id'>
) => {
  const user = supabase.auth.user()

  return await supabase
    .from<Interest>('interests')
    .update({
      comment: params.comment,
    })
    .match({
      user_id: user.id,
      idea_id: params.idea_id,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchInterest = async (params: Pick<Interest, 'idea_id'>) => {
  const user = supabase.auth.user()

  return await supabase
    .from<Interest>('interests')
    .select('*')
    .eq('user_id', user.id)
    .eq('idea_id', params.idea_id)
    .maybeSingle()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const turnOnIsCelebrated = async () => {
  const user = supabase.auth.user()

  return await supabase
    .from<User>('users')
    .update({
      is_celebrated: true,
    })
    .match({
      id: user.id,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const createTeam = async (params: {
  slackChannelName: string
  slackChannelId: string
  otherMemberIds: string[]
}) => {
  const team = await supabase
    .from<Team>('teams')
    .insert([
      {
        slack_channel_id: params.slackChannelId,
        slack_channel_name: params.slackChannelName,
      },
    ])
    .maybeSingle()
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })

  // リーダーを追加
  const leader = supabase.auth.user()
  await supabase
    .from<TeamConnection>('team_connections')
    .insert([
      {
        team_id: team.id,
        user_id: leader.id,
        is_leader: true,
      },
    ])
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })

  // 他のメンバーを追加
  params.otherMemberIds.map(async (userId) => {
    await supabase
      .from<TeamConnection>('team_connections')
      .insert([
        {
          team_id: team.id,
          user_id: userId,
        },
      ])
      .then(({ error, data }) => {
        if (error) {
          console.log('supabase error', error)
          throw error
        }
        return data
      })
  })
}

export const fetchTeams = async () => {
  return await supabase
    .from<Team>('teams_ordered')
    .select(
      'id, slack_channel_id, slack_channel_name, team_connections(user:users_ordered_by_this_week!user_id(id, picture, full_name, full_name_en, slack_id, profile, org, slack_channel, keywords, weekly_total_minutes)), total_team_minutes'
    )
    .order('total_team_minutes', {
      ascending: false,
    })
    // @ts-expect-error
    .order('is_leader', {
      foreignTable: 'team_connections',
      ascending: false,
    })
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchIsAlreadyTeamLeader = async () => {
  const user = supabase.auth.user()

  return await supabase
    .from<TeamConnection>('team_connections')
    .select('*', { count: 'exact' })
    .eq('user_id', user.id)
    .eq('is_leader', true)
    .then(({ error, data, count }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return count > 0 ? true : false
    })
}

export const fetchFreeIdeasPaginated = async ({
  // 1ページ目から始める
  pageParam = 1,
}: {
  pageParam: number
}) => {
  const size = 20
  // 1ページ目:0, 2ページ目: 20, 3ページ目: 40....
  const from = pageParam * size - size
  // 1ページ目:19, 2ページ目: 39, 3ページ目: 59....
  const to = from + size - 1

  const user = supabase.auth.user()
  return await supabase
    .from<Idea>('ideas_free')
    .select(
      // user:usersとすると動かない。（ここではusersはテーブル名)
      // hintを設定する必要がある
      // see: https://postgrest.org/en/v8.0/api.html#hint-disambiguation
      // 「hint can be a table name, foreign key constraint name or column name.」とある
      'id, created_at, updated_at, title, excerpt, html, user_id, status, user:users!user_id(id, picture, full_name, slack_id), total_votes(points), my_votes:votes( point ), total_interests(total), fires(count)'
    )
    .eq('status', Status.PUBLISHED)
    // @ts-expect-error
    .eq('votes.user_id', user.id)
    .range(from, to)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}

export const fetchFreeUsers = async () => {
  return await supabase
    .from<User>('users_free')
    .select('*')
    .neq('is_hidden', true)
    .then(({ error, data }) => {
      if (error) {
        console.log('supabase error', error)
        throw error
      }
      return data
    })
}
