import { Shortcut } from '@shopify/react-shortcuts'
import cc from 'clsx'
import { PropsWithChildren, useRef } from 'react'
import {
  FocusManager,
  SunbeamProvider,
  useFocusable,
  useSunbeam,
} from 'react-sunbeam'

export const focusManager = new FocusManager({
  initialFocusPath: [''],
})

export const SpatialNavProvider = (props: PropsWithChildren<{}>) => {
  return (
    <>
      <SunbeamProvider focusManager={focusManager}>
        <SunbeamGlobalKeyEnabler>{props.children}</SunbeamGlobalKeyEnabler>
      </SunbeamProvider>
    </>
  )
}

const SunbeamGlobalKeyEnabler = (props: PropsWithChildren<{}>) => {
  const { moveFocusLeft, moveFocusRight, moveFocusUp, moveFocusDown } =
    useSunbeam()

  const trigger = (direction: 'h' | 'l' | 'j' | 'k') => {
    switch (direction) {
      case 'l':
        moveFocusRight()
        break
      case 'h':
        moveFocusLeft()
        break
      case 'k':
        moveFocusUp()
        break
      case 'j':
        moveFocusDown()
        break
    }
    return
  }

  return (
    <>
      <Shortcut
        ordered={['ArrowRight']}
        onMatch={() => trigger('l')}
        allowDefault
      />
      <Shortcut ordered={['l']} onMatch={() => trigger('l')} allowDefault />
      <Shortcut
        ordered={['ArrowLeft']}
        onMatch={() => trigger('h')}
        allowDefault
      />
      <Shortcut ordered={['h']} onMatch={() => trigger('h')} allowDefault />
      <Shortcut
        ordered={['ArrowDown']}
        onMatch={() => trigger('j')}
        allowDefault
      />
      <Shortcut ordered={['j']} onMatch={() => trigger('j')} allowDefault />
      <Shortcut
        ordered={['ArrowUp']}
        onMatch={() => trigger('k')}
        allowDefault
      />
      <Shortcut ordered={['k']} onMatch={() => trigger('k')} allowDefault />
      {props.children}
    </>
  )
}

// デフォルトのFocusableではscrollしないので、こちらを使う
export const FocusItem = (
  props: PropsWithChildren<{
    onFocus?: () => void
  }>
) => {
  const ref = useRef(null)
  const { focused } = useFocusable({
    elementRef: ref,
    onFocus: () => {
      props.onFocus && props.onFocus()
      scrollIn()
    },
  })

  const scrollIn = () => {
    ref.current.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    })
  }

  return (
    <>
      <div ref={ref} className={cc(focused && 'active')}>
        {props.children}
      </div>
    </>
  )
}
