// borrowed from:
// https://github.com/supabase/ui/blob/master/src/components/Auth/UserContext.tsx
import { SupabaseClient } from '@supabase/supabase-js'
import { useRouter } from 'next/router'
import { createContext, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { User } from '~/types'
import { fetchCurrentUser } from '~/utils/queries'

const UserContext = createContext<{
  user: User | null
}>({ user: null })

export interface Props {
  supabase: SupabaseClient
  [propName: string]: any
}

export const UserContextProvider = (props: Props) => {
  const { supabase } = props
  const router = useRouter()
  const [isReady, setIsReady] = useState(false)

  // supabaseのpublic.users上のuser (auth.usersではない)
  const { data: user, refetch } = useQuery(
    'currentUser',
    () => fetchCurrentUser(),
    {
      // 初回は止めておく
      enabled: isReady,
      staleTime: 1000 * 60 * 3, // 3min
    }
  )

  useEffect(() => {
    const session = supabase.auth.session()
    if (session?.user) {
      setIsReady(true)
    }

    supabase.auth.onAuthStateChange(async (event, session) => {
      if (session?.user) {
        setIsReady(true)
        refetch()
      }

      // ssrやmiddlewareで使うために必要
      // TODO: タブがたくさんあるとたくさんリクエストが出てしまう
      await fetch('/api/auth', {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'same-origin',
        body: JSON.stringify({ event, session }),
      })

      if (event === 'SIGNED_IN' || event === 'SIGNED_OUT') {
        router.reload()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const value = {
    user,
  }
  return <UserContext.Provider value={value} {...props} />
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`)
  }
  return context.user
}
