import { createClient } from '@supabase/supabase-js'

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL as string
const supabaseAnonKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY as string
const supabaseSecretServiceKey = process.env
  .SUPABASE_SECRET_SERVICE_KEY as string

export const supabase =
  typeof window === 'undefined'
    ? createClient(supabaseUrl, supabaseSecretServiceKey, {
        fetch,
        autoRefreshToken: true,
      })
    : createClient(supabaseUrl, supabaseAnonKey, {
        autoRefreshToken: true,
      })
